import React, { Component } from 'react'
import styled from 'styled-components'

import Layout from '../Components/layout.js'
import SEO from '../Components/seo.js'
import ContactInfo from '../Components/Contacts.jsx'

const MapDiv = styled.div`
  display: flex;
  justify-content: center;
`
const iFrame = {
  __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13215.517495055237!2d-118.0905793!3d34.0982305!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbcf4c00e103acaaa!2sL%26H%20Tag%20and%20Label!5e0!3m2!1sen!2sus!4v1567558678966!5m2!1sen!2sus" width="600" height="600" frameborder="0" style="border:0;width:100%;" allowfullscreen=""></iframe>`
}

class Contact extends Component {
  render() {
    return (
      <Layout>
        <SEO title='Contact'/>
        <ContactInfo/>
        <MapDiv dangerouslySetInnerHTML={iFrame} />
      </Layout>
    )
  }
}

export default Contact;