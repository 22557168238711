import React from "react"
import styled from "styled-components"

import {device} from '../css/CSSMediaQueries.js'

import Row from '../Components/Rows.jsx'
import Picture from '../Components/Picture.jsx'

const ListOfInformation = styled.div`
  display: grid;
  justify-content: left;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
  
  > div h6, b {
    font-size: 18px;
    background-color: white;
  }
  line-height: 1.5;
`

const InformationEntry = styled.div`
  border: 1px solid #ddd;
  background-color: white;
  max-width: 350px;
  padding: 30px;
  > h4 {
    background-color: white;
    font-size: 30px;
    margin: 0px;
    line-height: 1;
    margin-bottom: 30px;
  }
`

class Contacts extends React.Component {
  render() {
    return(
      <div>
        <Picture splash src='OneRoll3'/>
        <Row>
          <ListOfInformation>
            <InformationEntry>
              <h4>Contact:</h4>
              <h6><b>Email:</b> Sales@alabel.com</h6>
              <h6><b>Phone:</b> +1(626)285-8335</h6>
              <h6><b>Fax:</b> +1(626)286-7375</h6>
            </InformationEntry>
            <InformationEntry>
              <h4>Business Hours:</h4>
              <h6>Monday - Friday: 8:30AM - 5:00PM</h6>
              <h6>Saturday and Sunday: Closed</h6>
            </InformationEntry>
            <InformationEntry>
              <h4>Address:</h4>
              <h6>324 S. San Gabriel Blvd.</h6>
              <h6>San Gabriel, CA 91776-1931</h6>
            </InformationEntry>
          </ListOfInformation>
        </Row>
      </div>
    )
  }
}

export default Contacts